<script setup>
import { inject } from 'vue'
const siteConfig = inject('siteConfig')
</script>
<template>
  <footer>
    <div class="container">
      <p class="store-copyright small-regular">
        Powered by <a :href="`https://www.artcentercanvas.com/?ref=${siteConfig.key}`">Art Center Canvas</a> | Copyright © {{ (new Date()).getFullYear() }} {{ siteConfig.org.name }}. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
footer {
  @apply sticky top-full left-0 w-full py-5 lg:py-6 border-t border-gray-300;
  .store-copyright {
    @apply text-secondary-text text-center;
    a {
      @apply font-bold border-b-2 border-gray-400 transition hover:border-accent-2 hover:text-accent-2;
    }
  }
}
</style>
