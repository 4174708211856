<script setup>
import { ref, inject } from 'vue'
import QuickLinksWithNested from './QuickLinksWithNested.vue'
import CartSection from '../CartSection.vue'

const siteConfig = inject('siteConfig')

// XXX FIXME remove routes
const quickLinks = ref(siteConfig.quickLinks)
</script>

<template>
  <div class="pre-nav">
    <div class="container">
      <div class="search-store">
        <!-- <button class="search-btn body-bold" @click="openSearchBox"> <Icon icon="heroicons-outline:search" class="icon" /> <span class="relative top-[.5px]">Search</span> </button>
            <transition name="fade">
              <div class="search-box" v-if="openedSearchBox" @click.self="closeSearchBox">
                <header class="box-header">
                  <label class="search-field">
                    <Icon icon="heroicons-outline:search" class="icon search" />
                    <input type="search" placeholder="Search webaddress.com" class="field-input md:xlarge-text font-normal" v-model="searchQuery" />
                    <button class="btn-close" @click="closeSearchBox">
                      <Icon icon="heroicons-solid:x" class="icon" />
                    </button>
                  </label>
                </header>
                <div class="results-box">
                  <h4 class="mb-5 small-bold uppercase">
                    <span v-if="searchQuery">Search Results (2)</span>
                    <span v-else>Quick Links</span>
                  </h4>
                  <ul class="results-list">
                    <template v-if="searchQuery">
                    <li v-for="link, index in searchResults" :key="index">
                      <router-link class="link body-bold text-accent-1" :to="link.route" v-html="link.title" @click="closeSearchBox"></router-link>
                    </li>
                    </template>
                    <template v-else>
                      <li v-for="link, index in quickLinks" :key="index">
                        <router-link class="link" :to="link.route" @click="closeSearchBox">
                          <Icon icon="heroicons-outline:search" v-if="true" /> {{ link.title }}
                          </router-link>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </transition> -->
      </div>

      <div class="column">
        <QuickLinksWithNested :quickLinks="quickLinks" :menuBtnClasses="'uppercase'" />
      </div>
    </div>
  </div>
</template>
