import { defineStore } from 'pinia'
import siteConfig from '@/config.json'
import axios from 'axios'
import { getInstructorImageUrl, normalize } from './services/instructor'

export const useInstructorStore = defineStore('InstructorStore', {
  state: () => {
    return {
      activeClassInstructorIds: [],
      openClassInstructorIds: [],
      instructors: [],
      instructorsById: {},
      groupsForInstructorId: {},
      loadingInstructors: false,
    }
  },
  getters: {
    instructorsWithOpenClasses() {
      return this.instructors.filter((i) => this.openClassInstructorIds.includes(i.instructorId))
    },

    instructorsWithActiveClasses() {
      return this.instructors.filter((i) => this.activeClassInstructorIds.includes(i.instructorId))
    },

    instructorsForInstructorPage() {
      let listOfInstructors = [...this.instructors]

      if (siteConfig.features.hideInstructorsWithoutClasses) {
        listOfInstructors = [...this.instructorsWithOpenClasses]
      }

      if (siteConfig.features.instructorExclusions && siteConfig.features.instructorExclusions.length) {
        listOfInstructors = listOfInstructors.filter((i) => {
          return !siteConfig.features.instructorExclusions.includes((i.fullName || '').trim())
        })
      }

      for (const inst of listOfInstructors) {
        if (!inst.groups) {
          inst.groups = []
        }
        if (this.groupsForInstructorId[inst.instructorId]) {
          inst.groups = this.groupsForInstructorId[inst.instructorId]
        }
      }

      return listOfInstructors
    },
  },
  actions: {
    updateOpenClassInstructorIds(openClassInstructorIds) {
      this.openClassInstructorIds = openClassInstructorIds
    },
    updateActiveClassInstructorIds(activeClassInstructorIds) {
      this.activeClassInstructorIds = activeClassInstructorIds
    },
    async fetchInstructors() {
      this.loadingInstructors = true

      // const req = axios.get(`${siteConfig.legacyApiBaseUrl}/classes/allinstructors`, {
      //   auth: siteConfig.legacyApiAuth
      // })

      try {
        const { data } = await axios.get(`${siteConfig.apiBaseUrl}/classes/instructors`)
        let instructors = data

        const instructorsById = {}

        for (const instructor of instructors) {
          instructorsById[instructor.instructorId] = instructor
          instructor.imageUrl = getInstructorImageUrl(instructor.imageUrl)
        }

        this.instructorsById = instructorsById

        if (siteConfig.features.instructorExclusions && siteConfig.features.instructorExclusions.length) {
          instructors = instructors.filter((inst) => !siteConfig.features.instructorExclusions.includes(inst.fullName))
        }

        this.instructors = instructors.sort((a, b) => {
          if (normalize(a.lastName) < normalize(b.lastName)) {
            return -1
          } else if (normalize(a.lastName) > normalize(b.lastName)) {
            return 1
          }
          return 0
        })

        this.loadingInstructors = false
      } catch (error) {
        this.loadingInstructors = false
        console.log('error', error)
      } finally {
        if (siteConfig.key === 'perkins') {
          this.fetchInstructorGroups()
        }
      }
    },

    async fetchInstructorGroups() {
      try {
        const { data } = await axios.get(`${siteConfig.apiBaseUrl}/reports/queries/perkins/instructor-groups`)

        const groups = data.results

        if (groups?.length) {
          for (const group of groups) {
            this.groupsForInstructorId[group.person_id] = this.groupsForInstructorId[group.person_id] || []
            this.groupsForInstructorId[group.person_id].push(group.group_name)
          }
        }
      } catch (e) {
        console.log('fetchInstructorGroups error', e)
      }
    },
  },
})
