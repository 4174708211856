<script setup>
import { onMounted, onUnmounted, watchEffect } from 'vue'
import { useCommonStore } from '@/stores/CommonStore'

const commonStore = useCommonStore()

onMounted(() => {
  watchEffect(() => {
    if (commonStore.windowInnerWidth < 768) {
      document.body.style.overflow = 'visible'
    } else {
      document.body.style.overflow = 'hidden'
    }
  })
})

onUnmounted(() => {
  document.body.style.overflow = 'visible'
})
</script>

<template>
  <section class="base-modal">
    <div class="backdrop">
      <div class="container">
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>