export const initRangeFunc = () => {
  if (typeof Number.prototype.isBetween === 'undefined') {
    Number.prototype.isBetween = function (min, max, notBoundaries) {
      var between = false
      if (notBoundaries) {
        if (this < max && this > min) {
          between = true
        }
      } else {
        if (this <= max && this >= min) between = true
      }
      return between
    }
  }

  if (typeof Array.prototype.isRangeCrossed === 'undefined') {
    Array.prototype.isRangeCrossed = function (target, notBoundaries) {
      var result = false
      if (
        target[0].isBetween(this[0], this[1], notBoundaries) ||
        target[1].isBetween(this[0], this[1], notBoundaries)
      ) {
        result = true
      }
      return result
    }
  }
}
if (typeof Number.prototype.isBetween === 'undefined') {
  Number.prototype.isBetween = function (min, max, notBoundaries) {
    var between = false
    if (notBoundaries) {
      if (this < max && this > min) {
        between = true
      }
    } else {
      if (this <= max && this >= min) between = true
    }
    return between
  }
}

if (typeof Array.prototype.isRangeCrossed === 'undefined') {
  Array.prototype.isRangeCrossed = function (target, notBoundaries) {
    var result = false
    if (target[0].isBetween(this[0], this[1], notBoundaries) || target[1].isBetween(this[0], this[1], notBoundaries)) {
      result = true
    }
    return result
  }
}
