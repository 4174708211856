import { defineStore } from 'pinia'

export const useCommonStore = defineStore('CommonStore', {
  state: () => {
    return {
      windowInnerWidth: null,
    }
  },
  actions: {
    updateWindowWidth(width) {
      this.windowInnerWidth = width
    },
  },
})
