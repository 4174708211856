<script setup>
import { ref, toRefs, watchEffect, inject } from 'vue'
import { useHeader } from '@/hooks/useHeader'
import { useHeaderStore } from '@/stores/HeaderStore'
import { useUserStore } from '@/stores/UserStore'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

const siteConfig = inject('siteConfig')

const { getOverviewItems } = useHeader()
const headerStore = useHeaderStore()
const userStore = useUserStore()

const flushCachesLabel = ref('Flush Caches')
const overviewItems = ref([])

const flushCaches = () => {
  userStore.flushCaches()
  flushCachesLabel.value = 'Flushing...'
  setTimeout(() => {
    flushCachesLabel.value = 'Flush Caches'
  }, 5000)
}

watchEffect(() => {
  overviewItems.value = getOverviewItems(siteConfig, userStore)
})
</script>

<template>
  <div class="auth-info">
    <Menu as="div" class="user-info" v-if="userStore.loggedIn" v-slot="{ open }">
      <MenuButton class="toggler large-bold" :class="{ toggled: open }">
        Hi, {{ userStore.account?.firstName || userStore.firstName }}
        <Icon icon="heroicons-solid:chevron-down" class="icon" />
      </MenuButton>
      <transition name="scale">
        <MenuItems as="ul" class="account-dropdown body-regular">
          <MenuItem v-for="(item, index) in overviewItems" :key="index" v-slot="{ close }">
            <div>
              <div @click="close">
                <router-link
                  class="list-link large-regular lg:body-regular"
                  :to="{ name: item.routeName }"
                  :data-link="item.text"
                  @click="[headerStore.updateMenuOpenState(false)]"
                >
                  {{ item.text }}
                </router-link>
              </div>
            </div>
          </MenuItem>
          <MenuItem as="li" class="log-out" v-if="userStore.isStaff" @click="flushCaches">
            <button class="outlined-accent-1"> {{ flushCachesLabel }} </button>
          </MenuItem>
          <MenuItem
            class="log-out" as="li"
            @click="
              ;[
                headerStore.updateMenuOpenState(false),
                userStore.logOut(),
              ]
            "
          >
            <button class="outlined-accent-1">
              Sign Out
            </button>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
    <router-link
      :to="{ name: 'Sign In' }"
      class="outlined-accent-1 sign-link body-regular lg:small-regular"
      @click.prevent="headerStore.updateMenuOpenState(false)"
      v-else
    >
      Sign in
    </router-link>
  </div>
</template>
