// Monthly, Weekly, Daily
export const UNITS_OF_TIME_VIEW = {
  Monthly: 'Monthly',
  Weekly: 'Weekly',
  Daily: 'Daily',
}

export const FIRST_DAY_IN_MONTH = 1
export const LAST_MONTH = 12
export const DAYS_IN_MONTH_CALENDAR = 42
export const COUNT_DAYS_IN_MONTH_30 = 30
export const COUNT_DAYS_IN_MONTH_31 = 31
export const COUNT_DAYS_IN_FEBRUARY = 28
export const COUNT_DAYS_IN_LEAP_FEBRUARY = 29
export const COUNT_DAYS_IN_A_WEEK = 7

/* Grid constants */
export const START_COLUMN_POINT_DEFAULT = 1
export const END_COLUMN_POINT_DEFAULT = 12
export const COLUMN_POINT_3 = 3
export const COLUMN_POINT_4 = 4
export const COLUMN_POINT_6 = 6
export const COLUMN_POINT_8 = 8
export const COLUMN_POINT_9 = 9
export const START_DEFAULT_HOUR_FOR_DAILY = 8
export const COUNT_OF_GRID_ROWS_IF_WE_START_FROM_8 = 192
export const COUNT_OF_GRID_ROWS_WITH_STEP_5_MIN = 288
export const NUMBERS_OF_HOURS_IN_A_DAY = 24
export const GRID_STEPS_PER_ONE_HOUR = 12

/* Popup constants */
export const POPUP_HEIGHT = 410
export const POPUP_WIDTH = 244

/* Colors according to the type class */
export const EVENT_CLASS_TYPES_COLORS = [
  '#E9853D',
  '#2DB179',
  '#6251D5',
  '#DA6565',
  '#DFAA21',
  '#8E4B10',
  '#750F62',
  '#9CA3AF',
  '#CD5C5C',
  '#808000',
  '#E9967A',
  '#2EA290',
  '#E37A9F',
  '#62750F',
  '#AE8A47',
  '#DE9E1E',
  '#39387B',
  '#5E387B',
  '#7B384D',
  '#5D8289',
]

/* use in beforeRoute */
export const DATES_FORMAT = {
  Monthly: 'yyyy-MM-dd',
  Weekly: 'yyyy-MM-dd',
  Daily: 'yyyy-MM-dd',
}
