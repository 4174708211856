<script setup>
import { toRefs } from 'vue'
import { useHeader } from '../../../hooks/useHeader'
import QuickLinksWithNestedItems from './QuickLinksWithNestedItems.vue'

const props = defineProps({
  quickLinks: Array,
  menuBtnClasses: String
})
const { quickLinks } = toRefs(props)
const { closeSublinks, openSubmenu } = useHeader()
</script>

<template>
  <ul
    class="quick-links"
    v-click-outside="() => closeSublinks(quickLinks, 1280)"
  >
    <QuickLinksWithNestedItems
      :links="quickLinks"
      :menuBtnClasses="menuBtnClasses"
      @openSubmenu="openSubmenu"
    />
  </ul>
</template>
