import { defineStore } from 'pinia'

export const useHeaderStore = defineStore('HeaderStore', {
  state: () => {
    return {
      isMenuOpened: false,
    }
  },
  actions: {
    updateMenuOpenState(value) {
      this.isMenuOpened = value
    },
  },
})
