<script setup>
import { onMounted, onUnmounted } from 'vue';
const emit = defineEmits(['close', 'leave']);

onMounted(() => {
  document.body.style.overflow = 'hidden';
});

onUnmounted(() => {
  document.body.style.overflow = 'visible';
});

function emitClose() {
  emit('close');
}
</script>

<template>
  <transition name="fade" mode="out-in">
    <section class="base-alert">
      <div class="backdrop">
        <div class="content" v-click-outside="emitClose">
          <p>Are you sure to leave without saving?</p>

          <div class="actions">
            <button class="btn-accent-1 small-bold" @click="emit('leave')">Yes</button>
            <button class="outlined-accent-1 small-bold" @click="emitClose">No</button>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<style lang="postcss" scoped>
.base-alert {
  @apply fixed top-0 left-0 w-full h-full;
  z-index: 999;
  .backdrop {
    @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
    background: rgba(35, 35, 35, 0.29);
    z-index: -1;
    .content {
      @apply bg-white py-6 px-7 rounded-md text-center w-max;
      .actions {
        @apply flex items-center justify-between gap-x-4 mt-7;
        button {
          @apply flex-1 md:flex-none md:w-40 rounded-md py-1.5 transition;
        }
      }
    }
  }
}
</style>
