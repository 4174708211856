import { initRangeFunc } from './crossedRange'
import {
  NUMBER_1,
  NUMBER_2,
  NUMBER_3,
  NUMBER_28,
  COUNT_DAYS_IN_MONTH_31,
  COUNT_DAYS_IN_MONTH_30,
  COUNT_DAYS_IN_FEBRUARY,
  COUNT_DAYS_IN_LEAP_FEBRUARY,
  DAYS_IN_MONTH_CALENDAR,
  START_COLUMN_POINT_DEFAULT,
  END_COLUMN_POINT_DEFAULT,
  COLUMN_POINT_4,
  COLUMN_POINT_6,
  COLUMN_POINT_8,
  POPUP_HEIGHT,
  POPUP_WIDTH,
  UNITS_OF_TIME_VIEW,
  DATES_FORMAT,
} from '@/constants'
import sortBy from 'lodash/sortBy'
import { isValidDate, getDate } from '@/helpers/luxonDateAndTime'
import { toCapitalize } from '@/helpers/text'

initRangeFunc()

export const prepareCrossedEventsIndexesCombination = (events, unit) => {
  const crossedTimeRanges = []
  let single = []
  let isFindIncludedItem = false
  for (let i = 0; i < events.length; i++) {
    isFindIncludedItem = false
    for (let j = 0; j < events.length; j++) {
      /* no need to compare yourself */
      if (i === j) continue

      /* check whether one time interval is included in another
           for intervals where the start and end times coincide */
      if (events[i].range[0] === events[j].range[0] && events[i].range[1] === events[j].range[1]) {
        if (!crossedTimeRanges.length) {
          crossedTimeRanges.push([i, j])
          isFindIncludedItem = true
        } else {
          crossedTimeRanges.forEach((item, key) => {
            /* prepare crossed events indexes combination */
            if (item.includes(i) && !item.includes(j)) {
              crossedTimeRanges[key].push(j)
              isFindIncludedItem = true
            }

            if (item.includes(i) && item.includes(j)) {
              isFindIncludedItem = true
            }
          })
        }

        if (!isFindIncludedItem) {
          crossedTimeRanges.push([i, j])
          isFindIncludedItem = false
        }

        continue
      }

      /* check whether one time interval is included in another */
      if (events[i].range.isRangeCrossed(events[j].range, true)) {
        if (!crossedTimeRanges.length) {
          crossedTimeRanges.push([i, j])
          isFindIncludedItem = true
        } else {
          crossedTimeRanges.forEach((item, key) => {
            if (item.includes(i) && !item.includes(j)) {
              crossedTimeRanges[key].push(j)
              isFindIncludedItem = true
            } else if (!item.includes(i) && item.includes(j)) {
              crossedTimeRanges[key].push(i)
              isFindIncludedItem = true
            }

            if (item.includes(i) && item.includes(j)) {
              isFindIncludedItem = true
            }
          })
        }

        if (!isFindIncludedItem) {
          crossedTimeRanges.push([i, j])
          isFindIncludedItem = false
        }
      }
    }
  }

  for (let i = 0; i < crossedTimeRanges.length; i++) {
    for (let j = i + 1; j < crossedTimeRanges.length; j++) {
      const hasCommonNumber = crossedTimeRanges[i].some((num) => crossedTimeRanges[j].includes(num))
      if (hasCommonNumber) {
        crossedTimeRanges[i] = [...new Set([...crossedTimeRanges[i], ...crossedTimeRanges[j]])]
        crossedTimeRanges[j] = []
      }
    }
  }

  if (unit === UNITS_OF_TIME_VIEW.Monthly) {
    single = events.reduce((acc, item, index) => {
      if (crossedTimeRanges.length) {
        for (let i = 0; i < crossedTimeRanges.length; i++) {
          const hasCommonNumber = crossedTimeRanges[i].includes(index)
          if (!hasCommonNumber) acc.push(index)
        }
      } else {
        acc.push(index)
      }

      return acc
    }, [])
  }

  return { crossedTimeRanges, single }
}

export const setStartAndEndPositionIfWeHaveCrossedTimeInterval = (crossedTimeRanges, events, forDaily) => {
  /* each item from this crossedTimeRanges (Array) it is time crossed events indexes combination */
  for (let i = 0; i < crossedTimeRanges.length; i++) {
    let crossedRange = crossedTimeRanges[i]

    if (crossedRange.length === NUMBER_2) {
      events[crossedRange[0]].startPoint = START_COLUMN_POINT_DEFAULT
      events[crossedRange[0]].endPoint = COLUMN_POINT_6

      events[crossedRange[1]].startPoint = COLUMN_POINT_6
      events[crossedRange[1]].endPoint = END_COLUMN_POINT_DEFAULT
    }

    if (crossedRange.length === NUMBER_3) {
      events[crossedRange[0]].startPoint = START_COLUMN_POINT_DEFAULT
      events[crossedRange[0]].endPoint = COLUMN_POINT_4

      events[crossedRange[1]].startPoint = COLUMN_POINT_4
      events[crossedRange[1]].endPoint = COLUMN_POINT_8

      events[crossedRange[2]].startPoint = COLUMN_POINT_8
      events[crossedRange[2]].endPoint = END_COLUMN_POINT_DEFAULT
    }

    if (crossedRange.length > NUMBER_3 && forDaily) {
      crossedRange = sortBy(crossedRange)
      for (let i = 0; i < crossedRange.length; i++) {
        events[crossedRange[i]].startPoint = calculatePositionForEventDailyGrid(i, 10 / crossedRange.length).start
        events[crossedRange[i]].endPoint = calculatePositionForEventDailyGrid(i, 10 / crossedRange.length).end
      }
    } else if (crossedRange.length > NUMBER_3) {
      const crossedIndexesForOmitFromEvent = crossedRange.slice(2, crossedRange.length)

      events[crossedRange[0]].startPoint = START_COLUMN_POINT_DEFAULT
      events[crossedRange[0]].endPoint = COLUMN_POINT_4

      events[crossedRange[1]].startPoint = COLUMN_POINT_4
      events[crossedRange[1]].endPoint = COLUMN_POINT_8

      events[crossedRange[1]].showMore = true
      events[crossedRange[1]].moreCount = crossedIndexesForOmitFromEvent.length

      events = events.reduce((acc, item, index) => {
        if (crossedIndexesForOmitFromEvent.includes(index)) {
          item.shouldHide = true
        }

        acc.push(item)

        return acc
      }, [])
    }
  }

  return events
}

export const getDayWhenWeshouldFinishMonthlyView = (countOfDays, countOfDaysFromPreviousMonth, isInLeapYear) => {
  return countOfDays === COUNT_DAYS_IN_MONTH_30
    ? DAYS_IN_MONTH_CALENDAR - COUNT_DAYS_IN_MONTH_30 - countOfDaysFromPreviousMonth + NUMBER_1
    : countOfDays === COUNT_DAYS_IN_MONTH_31
    ? DAYS_IN_MONTH_CALENDAR - COUNT_DAYS_IN_MONTH_31 - countOfDaysFromPreviousMonth + NUMBER_1
    : isInLeapYear
    ? DAYS_IN_MONTH_CALENDAR - COUNT_DAYS_IN_LEAP_FEBRUARY - countOfDaysFromPreviousMonth + NUMBER_1
    : DAYS_IN_MONTH_CALENDAR - COUNT_DAYS_IN_FEBRUARY - countOfDaysFromPreviousMonth + NUMBER_1
}

export const prepareStartAndEndPositionPerEvent = (cls) => {
  const splittedStartTime = cls.startTimeFmt.split(' ')
  const hourStartPart = splittedStartTime[0].split(':')
  let startTimePosition = Number(hourStartPart[0])
  let startTimeLike24HoursFormat = startTimePosition

  if (splittedStartTime[1] === 'PM') {
    startTimePosition = startTimePosition === 12 ? startTimePosition : 12 + startTimePosition
    startTimeLike24HoursFormat = String(startTimePosition)
  }

  if (hourStartPart[1] !== '00') {
    // we created interval every 30 min 24 hours * 2 and + 1 === 30min
    startTimePosition = startTimePosition * 12 + Number(hourStartPart[1]) / 5
  } else {
    startTimePosition = startTimePosition * 12
  }
  startTimeLike24HoursFormat = `${startTimeLike24HoursFormat}:${hourStartPart[1]}`
  const splittedEndTime = cls.endTimeFmt.split(' ')
  const hourEndPart = splittedEndTime[0].split(':')
  let endTimePosition = Number(hourEndPart[0])
  let endTimeLike24HoursFormat = endTimePosition
  if (splittedEndTime[1] === 'PM') {
    endTimePosition = endTimePosition === 12 ? endTimePosition : 12 + endTimePosition
    endTimeLike24HoursFormat = String(endTimePosition)
  }

  if (hourEndPart[1] !== '00') {
    // we created interval every 30 min 24 hours * 2 and + 1 === 30min
    endTimePosition = endTimePosition * 12 + Number(hourEndPart[1]) / 5
  } else {
    endTimePosition = endTimePosition * 12
  }

  endTimeLike24HoursFormat = `${endTimeLike24HoursFormat}:${hourEndPart[1]}`

  return {
    startTimePosition: startTimePosition,
    endTimePosition: endTimePosition,
    startTimeLike24HoursFormat: parseFloat(startTimeLike24HoursFormat.replace(':', '.')),
    endTimeLike24HoursFormat: parseFloat(endTimeLike24HoursFormat.replace(':', '.')),
  }
}

export const calculatePositionPopup = ({ $event, unitOfTimeView }) => {
  let direction = 'left'
  let xPosition = 0
  let yPosition = 0
  const child = $event.target
  const parent = child.offsetParent
  const parentRect = parent.getBoundingClientRect()
  const childRect = child.getBoundingClientRect()

  const windowScreenWidthRight = window.innerWidth - childRect.right
  const windowScreenWidthLeft = window.innerHeight - childRect.left

  if (unitOfTimeView === UNITS_OF_TIME_VIEW.Daily) {
    if (windowScreenWidthRight < POPUP_WIDTH) {
      xPosition = -POPUP_WIDTH
      direction = 'right'
    } else {
      direction = 'left'
      xPosition = parentRect.width
    }
  } else {
    if (windowScreenWidthRight < POPUP_WIDTH) {
      xPosition = -POPUP_WIDTH
      direction = 'right'
    } else if (windowScreenWidthLeft < POPUP_WIDTH) {
      direction = 'left'
      xPosition = parentRect.width
    } else {
      direction = 'left'
      xPosition = parentRect.width
    }
  }

  // NUMBER_28 it is min row height === 30 min for weekly view
  const parentHeight = parentRect.height <= NUMBER_28 ? parentRect.height : parentRect.height / NUMBER_2

  yPosition = -POPUP_HEIGHT / NUMBER_2 + parentHeight

  return {
    xPosition,
    yPosition,
    direction,
  }
}

export const getEventColor = (classTypes, department) => {
  const event = classTypes.find((item) => item.label === department)

  return event ? event.color : '#73767d'
}

export const calculatePositionForGrid = (index, gridSize) => {
  const row = Math.floor(index / gridSize) // calculate the row number for the current index
  const col = index % gridSize // calculate the column number for the current index

  return row * gridSize * 12 + col * 12 + 1
}

export const calculatePositionForEventDailyGrid = (index, space) => {
  const start = Math.floor(index * space + 1)
  const end = Math.floor((index + 1) * space + 1)
  return { start, end }
}

export const beforeCalendarEnter = (to, from, next) => {
  // console.log('beforeCalendarEnter to', to, 'from', from)
  let { timeUnit, date } = to.params
  timeUnit = timeUnit ? toCapitalize(timeUnit) : timeUnit

  const isProperTimeUnit = timeUnit && Object.values(UNITS_OF_TIME_VIEW).includes(timeUnit)

  if (timeUnit && isProperTimeUnit && date && isValidDate(date)) return next()

  if (!timeUnit || !isProperTimeUnit) {
    timeUnit = UNITS_OF_TIME_VIEW.Monthly
  }

  if (!date || !isValidDate(date)) {
    date = getDate({ format: DATES_FORMAT[timeUnit] })
  }

  next({ path: `/calendar/${timeUnit.toLowerCase()}/${date}`, replace: true })
}

export const prepareGroupedEvents = ({ crossedTimeRanges, events, single }) => {
  const updatedEvents = []

  if (crossedTimeRanges.length) {
    for (let i = 0; i < crossedTimeRanges.length; i++) {
      const groupedEvents = []
      for (let j = 0; j < crossedTimeRanges[i].length; j++) {
        groupedEvents.push(events.find((item, index) => index === crossedTimeRanges[i][j]))
      }
      updatedEvents.push(groupedEvents.slice(0, 3))
    }
  }
  if (single && single.length) {
    const slicedSingle = crossedTimeRanges.length >= 1 ? single.slice(0, 1) : single.slice(0, 2)
    for (let i = 0; i < slicedSingle.length; i++) {
      const eventByIndex = events.find((item, index) => index === single[i])

      if (i < slicedSingle.length - 1) {
        const eventByNextIndex = events.find((item, index) => index === slicedSingle[i + 1])
        eventByIndex.hasMargin = eventByIndex.endTime !== eventByNextIndex.startTime
      }

      updatedEvents.push([eventByIndex])
    }
  }

  let slicedUpdatedEvents

  if (updatedEvents[0].length > 1) {
    slicedUpdatedEvents = updatedEvents.slice(0, 2)
  } else {
    slicedUpdatedEvents = updatedEvents.slice(0, 3)
  }

  return { slicedUpdatedEvents }
}

export const transition = () => {
  const slideDiv = document.querySelector('.slide')
  slideDiv.classList.add('slide-enter-active')
  setTimeout(() => {
    slideDiv.classList.remove('slide-enter-active')
  }, 300)
}
