import siteConfig from '@/config.json'

export const useCommon = () => {
  const basename = (path) => {
    let parts = path.split('/')
    if (parts.length) {
      return parts[parts.length - 1]
    }
    return null
  }
  const getImageUrl = (imageUrl, defaultLocalFile = siteConfig.org.logoFilename) => {
    let url =  `/sites/${siteConfig.key}/${defaultLocalFile}`
    if (imageUrl) {
      const imageName = basename(imageUrl)
      if (siteConfig.features?.cdn?.url) {
        // if (siteConfig.features.cdn.type === 'keycdn') {
        url = `${siteConfig.features.cdn.url}/library/system/${imageName}?width=986&height=740&fit=cover&enlarge=0&metadata=0`
      } else {
        url = imageUrl.includes('artist')
          ? `${siteConfig.legacyImagesBaseUrl}/artist/${imageName}`
          : imageUrl.includes('system/')
          ? `${siteConfig.legacyImagesBaseUrl}/system/${imageName}`
          : `${siteConfig.legacyImagesBaseUrl}/${imageName}`
      }
    }
    return url
  }

  const getImageUrlBase = (imageUrl, defaultLocalFile = siteConfig.org.logoFilename) => {
    let url =  `/sites/${siteConfig.key}/${defaultLocalFile}`
    if (imageUrl) {
      const imageName = basename(imageUrl)
      const system = imageUrl.includes('system/') ? 'system/' : ''
      url =`${siteConfig.legacyImagesBaseUrl}/${system}${imageName}`
    }
    return url
  }

  return {
    getImageUrl,
    getImageUrlBase
  }
}
