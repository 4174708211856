import { DateTime } from 'luxon'

export const dateFromISO = (isoDate) => {
  return DateTime.fromISO(isoDate)
}

export const hoursPerDayAMPM = () => {
  const hours = Array.from({ length: 24 }, (_, i) => i)
  return hours.map((hour) => DateTime.fromObject({ hour }).toFormat('ha'))
}

export const isValidDate = (dateString) => {
  const dateTime = DateTime.fromISO(dateString)
  return dateTime.isValid
}

export const getDate = ({ date = DateTime.now(), format = 'yyyy-MM-dd' }) => {
  return date.toFormat(format)
}

export const getWeekNumber = (date) => DateTime.fromISO(date).toFormat('WW')
