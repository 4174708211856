import { defineStore } from 'pinia'
import axios from 'axios'
import capitalize from 'lodash/capitalize'
import siteConfig from '@/config.json'
import { useCommon } from '@/hooks/useCommon'

const defaultSortBy = 'artistLastName'
export const useAlternativeShopStore = defineStore('AlternativeShopStore', {
  state: () => {
    return {
      shopItems: [],
      artist: null,
      shopItem: null,
      artists: [],
      productCategories: [],
      productTypes: [],
      productCategoriesBasedOnShopItems: [],
      productForms: [],
      productByArtist: [],
      selectedCategory: null,
      selectedType: null,
      selectedForm: null,
      selectedArtist: null,
      selectedTypeName: 'All products',
      sortBy: defaultSortBy,
      loadingProducts: false,
      fetchedProducts: false,
      featureSets: [],
      featureSetFilters: null,
    }
  },
  getters: {
    preparedShopItems() {
      let shopItems = this.shopItems

      if (this.selectedType) {
        shopItems = shopItems.filter((item) => item.itemType === this.selectedType.toLowerCase())
      }

      if (this.selectedCategory) {
        shopItems = shopItems.filter((item) => item.categoryId == this.selectedCategory.categoryId)
      }

      if (this.selectedForm) {
        shopItems = shopItems.filter((item) => item.medium.toLowerCase() === this.selectedForm)
      }

      if (this.selectedArtist) {
        shopItems = shopItems.filter((item) => item.artistName === this.selectedArtist)
      }

      if (this.featureSetFilters?.length) {
        const featureSets = this.featureSets?.filter((set) => this.featureSetFilters?.includes(set.recordId.toString()))
        shopItems = shopItems.filter((item) => featureSets.some((set) => set.items?.some((si) => si.itemId == item.id)))
      }

      const sortBy = this.sortBy || defaultSortBy
      shopItems = shopItems.sort((a, b) => {
        const valueA = a[sortBy]
        const valueB = b[sortBy]

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB)
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          return valueA - valueB
        } else {
          // Fallback to basic string comparison if types are different
          return String(valueA).localeCompare(String(valueB))
        }
      })

      return shopItems
    },
    sortByOptions() {
      return [
        { text: 'Artist Last Name', value: 'artistLastName' },
        { text: 'Type', value: 'itemType' },
        { text: 'Item Name', value: 'itemName' },
        { text: 'Medium', value: 'medium' },
        { text: 'Price', value: 'price' },
      ]
    },
    sortByText() {
      const option = this.sortByOptions.find((option) => option.value === this.sortBy)

      return option ? option.text : ''
    },
  },
  actions: {
    setFiltersFromUrl() {
      const searchParams = new URLSearchParams(window.location.search)
      this.featureSetFilters = searchParams.get('featureSetFilters') ? searchParams.get('featureSetFilters').split(',') : null
      this.selectedCategory = searchParams.get('category') || null
      this.selectedType = searchParams.get('type') || null
      this.selectedForm = searchParams.get('form') || null
      this.selectedArtist = searchParams.get('artist') || null
      this.sortBy = searchParams.get('sortBy') || defaultSortBy
    },
    updateSelectedCategory(selectedCategory) {
      this.updateQueryParam('category', selectedCategory)
      this.selectedCategory = selectedCategory
    },
    updateSelectedType(selectedType) {
      this.updateQueryParam('type', selectedType)
      this.selectedType = selectedType
    },
    updateSelectedForm(selectedForm) {
      this.updateQueryParam('form', selectedForm)
      this.selectedForm = selectedForm
    },
    updateSelectedArtist(selectedArtist) {
      this.updateQueryParam('artist', selectedArtist)
      this.selectedArtist = selectedArtist
    },
    updateSortBy(sortBy) {
      this.updateQueryParam('sortBy', sortBy)
      const options = this.sortByOptions.map(option => option.value)
      this.sortBy = options.includes(sortBy) ? sortBy : defaultSortBy
    },
    updateQueryParam(param, value) {
      const searchParams = new URLSearchParams(window.location.search)
      if (!value || Array.isArray(value) && !value.length) {
        searchParams.delete(param)
      } else {
        searchParams.set(param, value)
      }
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`
      window.history.pushState({}, '', newUrl)
    },
    updateFeatureSetFilters(featureSetFilters) {
      if (featureSetFilters && !Array.isArray(featureSetFilters)) {
        featureSetFilters = [featureSetFilters]
      }
      this.updateQueryParam('featureSetFilters', featureSetFilters)
      this.featureSetFilters = featureSetFilters
    },
    updateShopItem(shopItem) {
      this.shopItem = shopItem
    },

    formatShopItem(item) {
      if (!item) return null
      const { getImageUrl } = useCommon()
      const nameParts = item.artistName?.split(' ') || []
      item.artistLastName = nameParts[nameParts?.length - 1] || 'Missing'
      item.artistFirstName = nameParts?.slice(0, nameParts.length - 1)?.join(' ') || 'Name'
      item.imageUrl = item.imageUrl ? getImageUrl(item.imageUrl) : `/sites/${siteConfig.key}/${siteConfig.org.logoFilename}`
      item.images = [item.imageUrl, ...['imageUrl2', 'imageUrl3', 'imageUrl4'].reduce((acc, key) => {
        if (item[key]) {
          acc.push(getImageUrl(item[key]))
        }
        return acc
      }, [])]
      item.id = item.itemId
      return item
    },
    async fetchShopItems() {
      this.loadingProducts = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/salesitems/salesitemsallpublished`,
        )

        if (!data.ok) return

        this.shopItems = data.results.filter((item) => item.itemType !== 'ticket')?.map((item) => this.formatShopItem(item)) || []
        this.loadingProducts = false
        this.fetchedProducts = true
      } catch (error) {
        this.loadingProducts = false
        console.log('fetchShopItems error', error)
      }
    },
    async fetchFeatureSets() {
      const { data } = await axios.get(`${siteConfig.apiBaseUrl}/salesitems/featuresetspublished`)
      this.featureSets = data.results?.filter((set) => set.publish === true).map((set) => ({
        ...set,
        recordId: set.recordId.toString(),
      })) || []
      await Promise.all(this.featureSets.map((featureSet) =>
        this.fetchFeatureSetItems(featureSet.recordId)
      ))
    },
    async fetchFeatureSetItems(featureSetId) {
      const { data } = await axios.get(`${siteConfig.apiBaseUrl}/salesitems/featuresetitems/${featureSetId}`)
      console.log('data', data)
      data.results.forEach((item) => {
        const featureSet = this.featureSets.find((set) => set.recordId === featureSetId)
        if (featureSet) {
          featureSet['items'] = featureSet['items'] || []
          featureSet['items'].push(item)
        }
      })
    },
    fetchProductCategoriesAndArtistsBasedOnShopItems() {
      const categories = {}
      const artists = {}
      let shopItems = this.shopItems

      shopItems.forEach((item) => {
        if (!categories[item.itemType]) {
          categories[item.itemType] = item.itemType
        }

        if (!artists[item.artistName] && item.artistName) {
          artists[item.artistName] = item.artistName.trim()
        }
      })

      this.productByArtist = Object.values(artists).sort((a, b) => {
        const aLastName = a.split(' ').pop();
        const bLastName = b.split(' ').pop();
        return aLastName.localeCompare(bLastName);
      });
      this.productCategoriesBasedOnShopItems = Object.keys(categories)
    },
    async fetchProductFormsBasedOnShopItems() {
      try {
        // Actually using medium rather than form until form is part of salesitemsallpublished response
        this.productForms = this.shopItems.reduce((acc, item) => {
          if (!acc.includes(item.medium.toLowerCase())) {
            acc.push(item.medium.toLowerCase().trim())
          }

          return acc
        }, []).sort()
      } catch (error) {
        console.log('fetchProductsByArtistId error', error)
      }
    },
    async fetchProductCategories() {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/salesitems/salesitemcategories`,
        )

        if (!data.ok) return

        this.productCategories = data.results.filter((item) => item.publish !== false)
      } catch (error) {
        console.log('fetchProductCategoryNames error', error)
      }
    },
    async fetchProductCategoryTiles() {
      const productCategories = [
        {
          image: `/sites/${siteConfig.key}/${siteConfig.assets?.shop?.defaultImage || siteConfig?.assets.logo}`,
          heading: 'Shop All Items',
          route: '/shop/products',
        },
      ]

      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/salesitems/salesitemcategories`,
        )

        if (!data.ok) return

        productCategories.push(
          ...data.results.reduce((acc, item) => {
            if (item.publish === false) return acc
            let image = siteConfig?.assets?.shop?.categories?.[item.name] ?? siteConfig?.assets?.logo;
            acc.push({
              heading: `Shop ${capitalize(item.name)}`,
              image: `/sites/${siteConfig.key}/${image}`,
              route: '/shop/products',
              type: item.name,
            })

            return acc
          }, []),
        )

        this.productCategories = productCategories
      } catch (error) {
        console.log('fetchProductCategories error', error)
      }
    },
    async fetchProductsByArtistId(artistId) {
      this.artist = {}
      this.loadingProducts = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-by-artist?artistId=${artistId}`,
        )

        if (!data.ok) return

        this.artist = data.results[0]

        await this.fetchShopItems()

        this.shopItems = this.shopItems.filter((item) => item.artistId === this.artist.id)
      } catch (error) {
        this.loadingProducts = false
        console.log('fetchProductsByArtistId error', error)
      }
    },
    async productById(productId) {
      if (!productId) return
      if (!this.shopItems.length) await this.fetchShopItems()
      const filteredItems = this.shopItems.filter((item) => item.itemId == productId)
      let result = filteredItems.length ? filteredItems[0] : null
      if (!filteredItems.length) {
        try {
          const { data } = await axios.get(
            `${siteConfig.apiBaseUrl}/salesitems/salesitemlookup/${productId}`,
          )
          if (data.ok) result = this.formatShopItem(data.results)
          console.log("ITEM res", data, result)
        } catch (error) {
          console.log('productById error', error)
        }
      }

      return result
    },
    async fetchArtists() {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-artists?types=${siteConfig.features.shop.shopCategoriesTypes}`,
        )

        if (!data.ok) return

        this.artists = data.results
      } catch (error) {
        console.log('fetchProductById error', error)
      }
    },
    clearFilters() {
      this.selectedCategory = null
      this.selectedType = null
      this.selectedForm = null
      this.selectedArtist = null

      this.featureSetFilters = []
      this.productCategories = []
      this.productForms = []
      this.productByArtist = []
    },
    clearShopData() {
      this.shopItems = []
      this.artist = null
    },
  },
})
