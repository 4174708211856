<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

const scrollBtn = ref()
const windowTop = ref(0)

const scrollToTop = () => {
  (!window.requestAnimationFrame) ? window.scrollTo(0, 0) : window.scrollTo({ top: 0, behavior: 'smooth' })
;
}

const scrollHandler = () => {
  const windowTop = window.scrollY || document.documentElement.scrollTop;
  if (windowTop > 300) {
    scrollBtn.value.classList.add('visible', 'opacity-100')
    scrollBtn.value.classList.remove('invisible', 'opacity-50', 'opacity-0')
  } else {
    scrollBtn.value.classList.add('invisible', 'opacity-0')
    scrollBtn.value.classList.remove('visible', 'opacity-50', 'opacity-100')
  }
  if (windowTop > 1200) {
    // scrollBtn.value.classList.add('opacity-50')
    scrollBtn.value.classList.remove('opacity-100')
  }
}

onMounted(() => {
  window.addEventListener('scroll', scrollHandler)
})

onUnmounted(() => {
  window.removeEventListener('scroll', scrollHandler)
})
</script>

<template>
  <button
    @click.prevent="scrollToTop"
    ref="scrollBtn"
    class="
      fixed bottom-8 right-10 w-20 h-20 rounded-full bg-accent-1 body-bold
      uppercase text-white inline-flex flex-col items-center justify-center
      gap-y-0.5 shadow-md transition-all duration-300 invisible opacity-0 z-[9999]"
    :class="{'!visible !opacity-100': windowTop > 300}"
  >
    <Icon icon="heroicons-solid:arrow-up" class="text-2xl" /> Top
  </button>
</template>