<script setup>
import { ref, watchEffect } from 'vue';

import { ChevronUpDownIcon as SelectorIcon } from '@heroicons/vue/24/outline';

const props = defineProps(['dropdown']);
const emit = defineEmits(['updateSelected']);

// console.log('props.dropdown.selected', props.dropdown.selected)
const selectedItem = ref(null);
const openedDroplist = ref(false);

props.dropdown.selectedItem
  ? (selectedItem.value = props.dropdown.selectedItem)
  : props.dropdown.type === 'first'
  ? (selectedItem.value = 'Please select')
  : (selectedItem.value = props.dropdown.options[0]);

watchEffect(() => {
  if (props.dropdown.selectedItem) {
    // console.log('props.dropdown.selectedItem', props.dropdown.selectedItem)
    selectedItem.value = props.dropdown.selectedItem;
  }
});

const closeDroplist = () => {
  openedDroplist.value = false;
};

const hiddenInput = ref(null)

const updateSelectedItem = (option) => {
  hiddenInput.value = option
  if (selectedItem.value !== option) {
    selectedItem.value = option;
    setTimeout(() => closeDroplist(), 100);
    emit('updateSelected', option);
    console.log('updateSelected')
  } else {
    closeDroplist();
  }
};
</script>

<template>
  <div class="dropdown-wrapper">
    <input ref="hiddenInput" type="hidden" :name="dropdown.name" :value="selectedItem" />
    <section
      class="base-dropdown"
      v-click-outside="closeDroplist"
      :name="dropdown.name"
      :class="{ opened: openedDroplist }"
      @click.self="closeDroplist"
    >
      <div class="dropdown-overlay" @click.prevent="closeDroplist"></div>
      <h4
        class="dropdown-label small-bold mb-2"
        v-html="dropdown.label"
        v-if="dropdown.label"
      ></h4>

      <div
        class="selected-item"
        @click.prevent="openedDroplist = !openedDroplist"
        role="button"
      >
        <component
          :is="dropdown.icon"
          class="icon"
          v-if="dropdown.icon && selectedItem !== 'Please select'"
        />
        <p
          :class="{
            'text-secondary-text':
              selectedItem === 'Please select' ||
              selectedItem === 'Select a size' ||
              selectedItem === 'Select attendee',
          }"
          v-html="typeof selectedItem === 'object' ? selectedItem.label : selectedItem"
        ></p>

        <button class="selector-btn">
          <SelectorIcon class="icon" />
        </button>
      </div>

      <ul
        class="dropdown-list small-regular"
        v-if="openedDroplist"
        :class="dropdown.direction"
      >
        <li class="special-item small-bold" v-if="dropdown.type === 'first'">
          Please select
        </li>
        <li
          class="list-item"
          :class="{ 'selected !font-bold': selectedItem === option }"
          @click="updateSelectedItem(option)"
          v-for="(option, index) in dropdown.options"
          :key="index"
        >
          <component :is="dropdown.icon" class="icon" v-if="dropdown.icon" />
          <p v-if="typeof option === 'object'" v-html="option.label"></p>
          <p v-else>{{ option }}</p>
        </li>
      </ul>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
@import url('@/assets/css/base/dropdown.pcss');
</style>
