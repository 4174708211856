import { useHeaderStore } from '@/stores/HeaderStore'
import { useCommonStore } from '@/stores/CommonStore'

export const useHeader = () => {
  const closeSublinks = (fullList, start = 0, end = 9000) => {
    const commonStore = useCommonStore()
    if (commonStore.windowInnerWidth > start && commonStore.windowInnerWidth < end) {
      for (const prop of fullList) {
        if (prop.openedSublinks) {
          prop.openedSublinks = false
        }
      }
    }
  }

  const openSubmenu = (router, fullList, link) => {
    const headerStore = useHeaderStore()
    const commonStore = useCommonStore()

    if (link.subLinks && link.subLinks.length > 0) {
      fullList.forEach((quickLink) => {
        if (quickLink !== link) {
          quickLink.openedSublinks = false
        }
      })

      link.openedSublinks = !link.openedSublinks

      router.beforeEach((to) => {
        if (to.path === link.route) {
          return false
        } else {
          fullList.forEach((mainLink) => {
            mainLink.openedSublinks = false
          })
          headerStore.updateMenuOpenState(false)
          return true
        }
      })
      router.afterEach((to) => {
        link.subLinks.forEach((subLink) => {
          console.log('to.path', to.path, 'subLink.route', subLink.route)
          if (to.path === subLink.route) {
            // if (to.path.includes(subLink.route)) {
            fullList.forEach((mainLink) => {
              mainLink.isSelected = false
            })
            link.isSelected = true
            if (commonStore.windowInnerWidth && commonStore.windowInnerWidth < 1280) {
              link.openedSublinks = true
            }
          }
        })
      })
    } else {
      fullList.forEach((mainLink) => {
        mainLink.isSelected = false
      })
      headerStore.updateMenuOpenState(false)
    }
  }

  const preparedMainLinks = (siteConfig) => {
    let list = [{ title: 'Home', url: siteConfig.org.website, sortPriority: 999 }]

    if (siteConfig.modules.classes && !siteConfig.features.navigation?.hidden?.classes) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.classes || 'Classes',
        route: '/classes',
        sortPriority: 100,
      })
    }

    if (
      siteConfig.modules.classes &&
      !siteConfig.features.navigation?.hidden?.classes &&
      siteConfig.features.separateClassesAndWorkshops
    ) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.workshops || 'Workshops',
        route: '/workshops',
        sortPriority: 95,
      })
    }

    if (
      siteConfig.modules.classes &&
      !siteConfig.features.navigation?.hidden?.classes &&
      siteConfig.features.separateClassesAndCamps
    ) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.camps || 'Camps',
        route: 'Store Camps',
        sortPriority: 95,
      })
    }

    if (siteConfig.modules.calendar && !siteConfig.features.navigation?.hidden?.calendar) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.calendar || 'Calendar',
        route: '/calendar',
        sortPriority: 96,
      })
    }

    if (siteConfig.modules.events && !siteConfig.features.navigation?.hidden?.events) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.events || 'Events',
        route: '/events',
        sortPriority: siteConfig.features?.eventsBeforeClasses ? 110 : 90,
      })
    }

    if (siteConfig.modules.exhibitions && !siteConfig.features.navigation?.hidden?.exhibitions) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.exhibitions || 'Exhibitions',
        route: '/exhibitions',
        sortPriority: 80,
      })
    }

    if (siteConfig.modules.shop && !siteConfig.features.navigation?.hidden?.shop) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.shop || 'Shop',
        route: '/shop',
        sortPriority: 70,
      })
    }

    if (siteConfig.modules.instructors && !siteConfig.features.navigation?.hidden?.instructors) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.instructors || 'Instructors',
        route: '/instructors',
        sortPriority: 60,
      })
    }

    if (siteConfig.modules.memberships && !siteConfig.features.navigation?.hidden?.memberships) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.memberships || 'Memberships',
        route: '/memberships',
        sortPriority: 50,
      })
    }

    if (siteConfig.modules.donations && !siteConfig.features.navigation?.hidden?.donations) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.donate || 'Donate',
        route: '/donate',
        sortPriority: 40,
      })
    }

    if (siteConfig.modules.giftCertificates && !siteConfig.features.navigation?.hidden?.giftCertificates) {
      list.push({
        title: siteConfig.features?.navigation?.titles?.giftCertificates || 'Gift Certificates',
        route: '/gift-certificates',
        sortPriority: 30,
      })
    }

    list = list
      .sort((a, b) => {
        if ((a.sortPriority || 0) > (b.sortPriority || 0)) return 1
        if ((a.sortPriority || 0) < (b.sortPriority || 0)) return -1
        return 0
      })
      .reverse()

    return list
  }

  const getOverviewItems = (siteConfig, userStore) => {
    const items = [
      {
        text: 'Account Info',
        routeName: 'Account: Account Info',
      },
    ]

    if (siteConfig.features.rostersEnabled && userStore.isActiveInstructor && userStore.instructorClasses.length) {
      items.push({
        text: 'My Classes',
        routeName: 'Account: Instructor Classes',
      })
    }

    if (siteConfig.modules.memberships) {
      items.push({
        text: 'Membership Status',
        routeName: 'Account: Membership Status',
      })
    }

    if (siteConfig.modules.reports && userStore.isStaff) {
      items.push({
        text: 'Reports',
        routeName: 'Account: Reports',
      })
    }

    // items.push({
    //   text: 'Saved Profiles',
    //   routeName: 'Account: Saved Profiles',
    // })

    // XXX TODO Only show if exhibitions is enabled AND user has submissions
    // if (siteConfig.exhibitions) {
    //   {
    //     text: 'Submission History',
    //     routeName: 'Account: Submission History',
    //   }
    // }

    if (siteConfig.features.haveVault) {
      items.push({
        text: 'Saved Payment Methods',
        routeName: 'Account: Payment Methods',
      })
    }

    if (siteConfig.modules.history) {
      items.push({
        text: 'Order History',
        routeName: 'Account: Order History Table',
      })
    }

    return items
  }

  return { closeSublinks, openSubmenu, preparedMainLinks, getOverviewItems }
}
