<script setup>
import { watchEffect, inject, ref } from 'vue'
import PreNav from '../Nav/PreNav/index.vue'
import Nav from '../Nav/index.vue'
import { useCartStore } from '@/stores/CartStore'
import { useHeaderStore } from '@/stores/HeaderStore'
import { useCommonStore } from '@/stores/CommonStore'

const cartStore = useCartStore()
const headerStore = useHeaderStore()
const commonStore = useCommonStore()

const siteConfig = inject('siteConfig')

// XXX FIXME remove routes
const quickLinks = ref(siteConfig.quickLinks)

watchEffect(() => {
  if (
    (cartStore.openedCart && commonStore.windowInnerWidth < 1280) ||
    (headerStore.isMenuOpened && commonStore.windowInnerWidth < 1280)
  ) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'visible'
  }
})
</script>

<template>
  <header
    id="globalHeader"
    class="storefront-header"
    :class="{
      'opened-full-cart':
        cartStore.openedCart && commonStore.windowInnerWidth < 1280,
    }"
  >
    <PreNav v-if="quickLinks" />
    <Nav />
  </header>
</template>
<style>
@import url('../StoreHeader/index.pcss');
</style>
