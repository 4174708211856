import siteConfig from '@/config'
import axios from 'axios'

/* Request headers */

const commonHeaders = {
  'Content-Type': 'application/json; charset=utf-8',
  'X-Bend-API-Version': '3',
}

const guestHeaders = {
  Authorization: 'Basic ' + btoa(siteConfig.bendConfig?.appKey + ':' + siteConfig.bendConfig?.appSecret),
}

/* Instances */

export const guestInstance = axios.create({
  baseURL: siteConfig.bendConfig?.apiBaseUrl,
  headers: {
    ...commonHeaders,
    ...guestHeaders,
  },
})

export const authedInstance = axios.create({
  baseURL: siteConfig.bendConfig?.apiBaseUrl,
  headers: {
    ...commonHeaders,
  },
})
