import siteConfig from '@/config.json'

export const getInstructorImageUrl = (imageUrl) => {
  let url
  if (imageUrl) {
    if (siteConfig.key === 'phillyartcenter') {
      url = `${siteConfig.legacyImagesBaseUrl}${imageUrl}`
    } else {
      if (siteConfig.features?.cdn?.url) {
        url = `${siteConfig.features.cdn.url}/library/artist/${imageUrl}?width=400&height=400&crop=smart&enlarge=0&metadata=0`
      } else {
        url = `${siteConfig.legacyImagesBaseUrl}/artist/${imageUrl}`
      }
    }
  } else {
    url = `/sites/${siteConfig.key}/placeholder-avatar.jpg`
  }
  return url
}

export const normalize = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}
